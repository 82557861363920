<template>
  <v-container>
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Dodatkowe punkty</v-toolbar-title>
      </v-toolbar>
      <v-card-text>

        <!-- Select competitor -->
        <v-autocomplete label="Zawody" v-model="competition" :items="competitions"
                        item-text="name" hide-details return-object/>

        <!-- Set points -->
        <v-card v-if="associations.length !== 0" outlined class="mt-4">
          <v-card-title>Dodaj punkty</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete label="Zawodnik" v-model="setPersonId" :items="associations"
                                item-text="person_name" item-value="person_id" hide-details/>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete label="Konkurencja" v-model="setEvent" :items="events"
                                item-text="name" item-value="id" hide-details/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field label="Punkty" v-model="setPoint" type="number" min="-999999" max="999999" step="1"/>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="setPoints()">DODAJ PUNKTY</v-btn>
          </v-card-text>
        </v-card>

        <!-- Points list -->
        <v-card v-if="points.length !== 0" outlined class="mt-4">
          <v-card-title>Lista punktów</v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="points">
              <!--suppress HtmlUnknownAttribute -->
              <template v-slot:item.event_name="{item}">
                {{ $utils.events.EVENT_NAMES[item['event']] }}
              </template>
              <!--suppress HtmlUnknownAttribute -->
              <template v-slot:item.actions="{item}">
                <v-spacer/>
                <v-icon right @click="delPoints(item.person_id, item.event)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AdminPoints',
  data: () => ({
    competitions: [], competition: null, points: [], associations: [],
    setPersonId: null, setEvent: null, setPoint: 0, events: [
      {id: 0, name: 'Kostka 3x3x3'}, {id: 1, name: 'Kostka 2x2x2'}, {id: 2, name: 'Kostka 4x4x4'},
      {id: 3, name: 'Kostka 5x5x5'}, {id: 4, name: 'Kostka 6x6x6'}, {id: 5, name: 'Kostka 7x7x7'},
      {id: 6, name: '3x3x3 Bez patrzenia'}, {id: 7, name: '3x3x3 Najmniejsza liczba ruchów'},
      {id: 8, name: '3x3x3 Jedną ręką'}, {id: 9, name: 'Clock'}, {id: 10, name: 'Megaminx'},
      {id: 11, name: 'Pyraminx'}, {id: 12, name: 'Skewb'}, {id: 13, name: 'Square-1'},
      {id: 14, name: '4x4x4 Bez patrzenia'}, {id: 15, name: '5x5x5 Bez patrzenia'},
      {id: 16, name: '3x3x3 Wiele kostek bez patrzenia'}
    ], headers: [
      {text: 'Zawodnik', value: 'person_name', sortable: true, align: 'left'},
      {text: 'Konkurencja', value: 'event_name', sortable: true, align: 'left'},
      {text: 'Punkty', value: 'points', sortable: true, align: 'left'},
      {text: '', value: 'actions', sortable: false, align: 'right'}
    ]
  }),
  created() {
    this.$http.post('/api/admin/points/get-competitions').then((res) => {
      this.competitions = res.data;
    }).catch((e) => {
      console.log(e);
    });
  },
  methods: {
    loadCompetition() {
      if (this.competition == null) return;
      this.associations = [];
      this.points = [];
      this.$http.post('/api/admin/points/get-competition', {
        compId: this.competition['id'], competitionId: this.competition['live_id']
      }).then((res) => {
        this.associations = res.data.associations;
        this.points = res.data.points;
      }).catch((e) => {
        console.log(e);
      });
    },
    setPoints() {
      if (this.setPersonId == null || this.setEvent == null || parseInt(this.setPoint) === 0) return;
      this.$http.post('/api/admin/points/set-points', {
        compId: this.competition.id,
        personId: this.setPersonId,
        event: this.setEvent,
        points: parseInt(this.setPoint)
      }).then(() => {
        this.loadCompetition();
        this.setPersonId = null;
        this.setEvent = null;
        this.setPoint = '';
      }).catch((e) => {
        console.log(e);
      });
    },
    delPoints(personId, event) {
      this.$http.post('/api/admin/points/del-points', {
        compId: this.competition.id, personId: personId, event: event
      }).then(() => {
        this.loadCompetition();
      }).catch((e) => {
        console.log(e);
      });
    }
  },
  watch: {
    competition() {
      this.loadCompetition();
    }
  }
};
</script>
